<template>
<v-dialog v-model="$store.state.app.dialogs.settings" :max-width="currentTab == 2 ? 600 : 500">
    <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Einstellungen</v-card-title>
        <v-tabs v-model="currentTab">
            <v-tab>Preisgestaltung</v-tab>
            <v-tab>Termine</v-tab>
            <v-tab>Bezirksregierung</v-tab>
        </v-tabs>
        <v-card-text class="mt-4" v-if="(currentTab == 0)">
            <v-text-field v-model="price" label="Preis (€)" prepend-icon="mdi-cash" color="primaryLight" hide-details outlined dense type="number" class="mb-2"></v-text-field>
            <v-alert type="info" color="primary" dense class="mb-0" text>Eine Änderung des Preises wirkt sich nur auf die zukünftig erstellten Termine aus.</v-alert>

            <v-text-field v-model="teachingAids" label="Lehrmittelkosten (€)" prepend-icon="mdi-cash" color="primaryLight" hide-details outlined dense type="number" class="mb-2 mt-8"></v-text-field>
            <v-alert type="info" color="primary" dense class="mb-0" text>Eine Änderung des Preises wirkt sich nur auf die zukünftig erstellten Termine aus.</v-alert>

            <v-row>
                <v-col cols="6">
                    <v-text-field v-model="taxNormal" label="MwSt. normal" prepend-icon="mdi-percent" color="primaryLight" hide-details outlined dense class="mb-2 mt-8"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="taxTeachingAids" label="MwSt. Lehrmittel" prepend-icon="mdi-percent" color="primaryLight" hide-details outlined dense class="mb-2 mt-8"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="mt-4" v-else-if="(currentTab == 1)">
            <v-text-field v-model="attendees" label="Teilnehmeranzahl" prepend-icon="mdi-account-group" color="primaryLight" hide-details outlined dense class="mb-2 mt-8"></v-text-field>
            <v-alert type="info" color="primary" dense class="mb-0" text>Eine Änderung der Teilnehmeranzahl wirkt sich nur auf die zukünftig erstellten Termine aus.</v-alert></v-card-text>
        <v-card-text  class="mt-4" v-else-if="(currentTab == 2)">
            <v-text-field v-model="districtGovernment.email" label="E-Mail-Adresse" prepend-icon="mdi-email" color="primaryLight" hide-details outlined dense type="email" class="mb-8" />
            <v-row>
                <v-col cols="6">
                    <v-select v-model="districtGovernment.form" label="Andrede" :items="forms" prepend-icon="mdi-message" color="primaryLight" hide-details outlined dense type="email" class="mb-2" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field v-model="districtGovernment.name" label="Name" color="primaryLight" hide-details outlined dense type="email" class="mb-2" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="save" text color="primaryLight">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data: () => ({
        isLoading: false,
        currentTab: 0,
        price: "",
        teachingAids: "",
        attendees: "",
        taxNormal: "",
        taxTeachingAids: "",
        districtGovernment: {
            email: "",
            name: "",
            form: ""
        },
        forms: [
            "Sehr geehrter Herr",
            "Sehr geehrte Frau",
            "Guten Tag Herr",
            "Guten Tag Frau",
            "Guten Tag"
        ]
    }),
    methods: {
        floatToPriceString(val) {
            return val.toFixed(2).replace(",", ".").toString()
        },
        priceStringToFloat(val) {
            return parseFloat(val.replace(",", "."))
        },
        async save() {
            this.isLoading = true
            await this.$store.dispatch("setSetting", { key: "price", val: this.priceStringToFloat(this.price) })
            await this.$store.dispatch("setSetting", { key: "teachingAids", val: this.priceStringToFloat(this.teachingAids) })
            await this.$store.dispatch("setSetting", { key: "taxNormal", val: this.priceStringToFloat(this.taxNormal) })
            await this.$store.dispatch("setSetting", { key: "taxTeachingAids", val: this.priceStringToFloat(this.taxTeachingAids) })
            await this.$store.dispatch("setSetting", { key: "maxAttendees", val: parseInt(this.attendees) })
            await this.$store.dispatch("setSetting", { key: "districtGovernment.email", val: this.districtGovernment.email })
            await this.$store.dispatch("setSetting", { key: "districtGovernment.name", val: this.districtGovernment.name })
            await this.$store.dispatch("setSetting", { key: "districtGovernment.form", val: this.districtGovernment.form })
            this.$store.state.app.dialogs.settings = false
            this.isLoading = false
        }
    },
    async mounted() {
        this.price = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "price", def: 1200 }))
        this.teachingAids = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "teachingAids", def: 7.50 }))
        this.taxNormal = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "taxNormal", def: 19.00 }))
        this.taxTeachingAids = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "taxTeachingAids", def: 7.00 }))
        this.attendees = (await this.$store.dispatch("getSetting", { key: "maxAttendees", def: 40 })).toString()
        this.districtGovernment.email = (await this.$store.dispatch("getSetting", { key: "districtGovernment.email", def: "" }))
        this.districtGovernment.name = (await this.$store.dispatch("getSetting", { key: "districtGovernment.name", def: "" }))
        this.districtGovernment.form = (await this.$store.dispatch("getSetting", { key: "districtGovernment.form", def: "" }))
    }
}
</script>