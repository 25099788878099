import Vue from "vue"

const state = {
    isSignedIn: false,
    authDone: false,
    isAuthenticating: true,
    profile: {},
    userClaims: {
        dispatcherFor: [],
        companies: [],
        autobook: false,
        permissions: {
            appointments: false,
            companies: false,
            pricing: false,
            users: false,
            admin: false,
            billing: false,
            teacher: false
        }
    }
}

const getters = {
    userHasAnyPermission(state) {
        return Object.values(state.userClaims.permissions)
            .some(permission => permission)
    }
}

const mutations = {
    setUserClaims(state, claims) {
        if (claims && claims.dispatcherFor) {
            state.userClaims.dispatcherFor = claims.dispatcherFor
        } else {
            state.userClaims.dispatcherFor = []
        }
        if (claims && claims.companies) {
            state.userClaims.companies = claims.companies
        } else {
            state.userClaims.companies = []
        }
        if (claims && claims.autobook) {
            state.userClaims.autobook = claims.autobook
        } else {
            state.userClaims.autobook = false
        }
        if (claims && claims.permissions) {
            state.userClaims.permissions.appointments = claims.permissions.appointments === true
            state.userClaims.permissions.companies = claims.permissions.companies === true
            state.userClaims.permissions.pricing = claims.permissions.pricing === true
            state.userClaims.permissions.users = claims.permissions.users === true
            state.userClaims.permissions.admin = claims.permissions.admin === true
            state.userClaims.permissions.billing = claims.permissions.billing === true
            state.userClaims.permissions.teacher = claims.permissions.teacher === true
        } else {
            state.userClaims.permissions.appointments = false
            state.userClaims.permissions.companies = false
            state.userClaims.permissions.pricing = false
            state.userClaims.permissions.users = false
            state.userClaims.permissions.admin = false
            state.userClaims.permissions.billing = false
            state.userClaims.permissions.teacher = false
        }
    },
    setUserProfile(state, profile) {
        Vue.set(state, "profile", profile)
    }
}
export default { state, getters, mutations }
