<template>
	<div class="app-container">
        <EditAppointmentDialog v-if="$store.state.auth.userClaims.permissions.appointments" :active="dialogs.editAppointment.active"></EditAppointmentDialog>
        <v-row v-if="$store.getters.viewType == 'driver'">
            <v-col cols="12">
                <v-tabs v-model="currentTab">
                    <v-tab>Offnene Termine</v-tab>
                    <v-tab>Archivierte Termine</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row v-if="$store.getters.viewType != 'driver'">
            <v-col sm="6" cols="12">
                <v-select v-model="$store.state.app.filter.module" :items="moduleItems" label="Modul" outlined dense prepend-icon="mdi-puzzle" hide-details color="primaryLight"></v-select>
            </v-col>
            <v-col sm="6" cols="12">
                <v-select v-model="$store.state.app.filter.location" :items="locationItems" label="Standort" outlined dense prepend-icon="mdi-map-marker" hide-details color="primaryLight"></v-select>
            </v-col>
            <v-col  sm="6" cols="12">
                <v-select v-model="$store.state.app.filter.month" :items="monthItems" label="Monat" outlined dense prepend-icon="mdi-calendar" hide-details color="primaryLight"></v-select>
            </v-col>
            <v-col sm="6" cols="12">
                <v-select v-model="$store.state.app.filter.status" :items="statusItems" label="Status" outlined dense prepend-icon="mdi-list-status" hide-details color="primaryLight"></v-select>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.auth.userClaims.permissions.appointments">
            <v-col cols="12" class="text-center">
                <v-btn text @click="dialogs.editAppointment.active++"><v-icon>mdi-plus</v-icon>Termin hinzufügen</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.appointment.appointments.length > 0 && filteredAppointments.length > 0">
            <div v-if="this.$store.state.app.filter.status==='notRegistered'"> 
                <v-btn :disabled="loading"  color="primary" @click="sendRegisterEmail">Anmelden senden</v-btn>
                <v-progress-circular
                    v-if="loading"
                    color="primary"
                    class="ml-3"
                    indeterminate
                    :size="20"
                >
                </v-progress-circular>
            </div>
            <v-col cols="12" v-for="appointment in filteredAppointments" :key="appointment._id">
                <v-row>
                    <v-col cols="1" v-if="$store.state.app.filter.status==='notRegistered'"><v-checkbox v-model="registerList[appointment.id]"></v-checkbox></v-col>
                    <v-col :cols="$store.state.app.filter.status==='notRegistered'? 11 : 12">
                        <AppointmentItem @click="onClickAppointment" :appointment="appointment"></AppointmentItem>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else-if="$store.state.appointment.appointments.length > 0 && $store.getters.viewType != 'driver'">
            <v-col cols="12">
                <v-alert type="info" color="primaryLight" text dense>Es wurden keine Termine mit Ihrer Suche gefunden.</v-alert>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-alert type="info" color="primaryLight" text dense>
                    Sie haben derzeit keine bevorstehende Termine.
                    Sollten Sie bereits eine Termineinladung erhalten haben, nutzen Sie den Link innerhalb der E-Mail
                    um die Termindetails einsehen zu können.
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import AppointmentItem from "@/components/items/AppointmentItem.vue"
import EditAppointmentDialog from "@/components/dialogs/EditAppointmentDialog.vue"
import jsPDF from "jspdf"
import { applyPlugin } from "jspdf-autotable"
import { format } from "date-fns"
import { Buffer } from "buffer"
import { httpsCallable } from "@firebase/functions"
import {/*  firestore, */ functions } from "@/firebase"
// import { collection, doc, getDoc } from "firebase/firestore"
// import { Appointment } from "@/classes/Appointment"

applyPlugin(jsPDF)

const valueExists = (value, array) => {
    for (const arrayVal of array) {
        if (arrayVal.value === value) {
            return true
        }
    }
    return false
}

export default {
    data: () => ({
        registerList: {},
        loading: false,
        currentTab: 0,
        monthString: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember"
        ],
        statusItems: [
            { value: "all", text: "Alle" },
            { value: "active", text: "Ausstehend" },
            { value: "closed", text: "Abgeschlossen" },
            { value: "canceled", text: "Storniert" },
            { value: "notRegistered", text: "Nicht Angemeldet" }
        ],
        dialogs: {
            editAppointment: {
                active: 0
            }
        }
    }),
    computed: {
        moduleItems() {
            const modules = []
            for (const appointment of this.$store.state.appointment.appointments) {
                if (!valueExists(appointment.module, modules)) {
                    modules.push({ value: appointment.module, text: `Modul ${appointment.module}` })
                }
            }
            modules.sort((a, b) => {
                if (a.value < b.value) {
                    return -1
                } else if(a.value > b.value) {
                    return 1
                }
                return 0
            })
            modules.unshift({ value: "all", text: "Alle" })
            return modules
        },
        locationItems() {
            const locations = []
            for (const appointment of this.$store.state.appointment.appointments) {
                if (!valueExists(appointment.address.postcode, locations)) {
                    locations.push({ value: appointment.address.postcode, text: `${appointment.address.location} - ${appointment.address.postcode}`})
                }
            }
            locations.sort((a, b) => {
                if (a.text < b.text) {
                    return -1
                } else if(a.text > b.text) {
                    return 1
                }
                return 0
            })
            locations.unshift({ value: "all", text: "Alle" })
            return locations
        },
        monthItems() {
            const months = []
            for (const appointment of this.$store.state.appointment.appointments) {
                if (!valueExists(appointment.from.getMonth(), months)) {
                    months.push({ value: appointment.from.getMonth(), text: this.monthString[appointment.from.getMonth()]})
                }
            }
            months.sort((a, b) => {
                if (a.value < b.value) {
                    return -1
                } else if(a.value > b.value) {
                    return 1
                }
                return 0
            })
            months.unshift({ value: "all", text: "Alle" })
            return months
        },
        filteredAppointments() {
            const appointments = []
            for (const appointment of this.$store.state.appointment.appointments) {
                let add = true
                if (this.$store.state.app.filter.module != "all") {
                    if (this.$store.state.app.filter.module != appointment.module) {
                        add = false
                    }
                }
                if (this.$store.state.app.filter.location != "all") {
                    if (this.$store.state.app.filter.location != appointment.address.postcode) {
                        add = false
                    }
                }
                if (this.$store.state.app.filter.month != "all") {
                    if (this.$store.state.app.filter.month != appointment.from.getMonth()) {
                        add = false
                    }
                }

                switch (this.$store.state.app.filter.status) {
                    case "active":
                        if (appointment.canceled || appointment.closed) {
                            add = false
                        }
                        break
                    case "canceled":
                        if (!appointment.canceled) {
                            add = false
                        }
                        break
                    case "closed":
                        if (!appointment.closed) {
                            add = false
                        }
                        break
                    case "archive":
                        if (!appointment.closed && !appointment.canceled) {
                            add = false
                        }
                        break
                    case "notRegistered":
                        if (appointment.canceled || appointment.closed || appointment.registered) {
                            add = false
                        }
                        break
                    default:
                        break
                }
                if (this.$store.getters.selectedCompany && this.$store.getters.selectedCompany.slots == 0) {
                    if (!appointment.slots) {
                        add = false
                    } else if (!appointment.slots[this.$store.getters.selectedCompany.id] || appointment.slots[this.$store.getters.selectedCompany.id] <= 0) {
                        add = false
                    }
                }
                if (appointment.slots) {
                    if (this.$store.getters.selectedCompany && (!appointment.slots[this.$store.getters.selectedCompany.id] || appointment.slots[this.$store.getters.selectedCompany.id] <= 0)) {
                        add = false
                    } else if (!this.$store.getters.selectedCompany && !appointment.attendeeSubs.includes(this.$store.state.auth.profile.sub)) {
                        add = false
                    }
                }
                if (add) {
                    appointments.push(appointment)
                }
            }
            appointments.sort((a, b) => {
                if (a.from < b.from) {
                    return -1
                } else if (a.from > b.from) {
                    return 1
                }
                return 0
            })
            return appointments
        }
    },
    methods: {
        onClickAppointment(appointment) {
            this.$router.push(`/appointment/${appointment.id}`)
        },
        formatDate(time) {
            return format(time, "dd.MM.yyyy")
        },
        formatTeacher(teacher) {
            return `${teacher.firstName} ${teacher.lastName}`
        },
        async sendRegisterEmail() {
            const pdfs = []
            const selectedAppointments = this.filteredAppointments.filter((appointment)=>this.registerList[appointment.id] && Object.keys(this.registerList).includes(appointment.id))
            for (const appointment of selectedAppointments) {
                let module1, module2, module3, module4, module5
                if (appointment.module === 1) {
                    module1 = true
                } else if (appointment.module === 2) {
                    module2 = true
                } else if (appointment.module === 3) {
                    module3 = true
                } else if (appointment.module === 4) {
                    module4 = true
                } else if (appointment.module === 5) {
                    module5 = true
                }

                const pdf = `
                <div id="pdf" style="padding: 10px; width: 570px">
                    <div style="display: flex;justify-content: space-between">
                        <div>
                            <h4>
                            NEW mobil und aktiv Mönchengladbach GmbH
                            <br/>
                            Odenkirchener Str. 201
                            <br/>
                            41236 Mönchengladbach
                            </h4>
                        </div>
                        <div>
                            <p style="margin-bottom: 5px; font-size: 12px">Datum :   ${this.formatDate(new Date())}</p>
                            <p style="margin-bottom: 5px; font-size: 12px">Tel.:    02166 688-4619</p>
                            <p style="margin-bottom: 5px; font-size: 12px">E.Mail:  <a>dirk.offermann@new.de</a></p>
                            <p style="margin-bottom: 5px; font-size: 12px">Tel.:    02166 688-4619</p>
                            <p style="margin-bottom: 5px; font-size: 12px">Ansprechpartner:    Herr Offermann</p>
                            <p style="margin-bottom: 5px; font-size: 12px">Aktenzeichen:    25.01.14 - 02/22</p>
                            <p style="margin-bottom: 5px; font-size: 12px">E.Mail:    <a>dirk.offermann@new.de</a></p>
                        </div>
                    </div>
                    <div style="margin-top: 20px;">
                        <strong>
                            Anzeige gemäß § 7b Absatz 3 Satz 5 BKrFQG über die beabsichtigte <br/>
                            Durchführung einer Weiterbildung / einer beschleunigten Grundqualifikation
                        </strong>
                    </div>
                    <table border="1" class="dataframe" style="width: 100%; margin-top: 10px; font-size: 14px">
                        <tbody>
                            <tr>
                                <td style="padding: 4px;">Art</td>
                                <td style="padding: 4px;">Beschleunigte Grundqualifikitation Weiterbildung</td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">Datum der Schulung</td>
                                <td style="padding: 4px;">${this.formatDate(appointment.from)}</td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">Ort (Firma, Hotel, Fahrschule usw.)</td>
                                <td style="padding: 4px; ">
                                    NEW mobil und aktiv Mönchengladbach GmbH<br/>${appointment?.address?.street} ${appointment?.address?.number}<br/>
                                    ${appointment?.address?.postcode} ${appointment?.address?.location}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">Zeit (von bis einschließlich Pausen)</td>
                                <td style="padding: 4px;">${format(appointment.from, "HH:mm")} Uhr bis ${format(appointment.to, "HH:mm")} Uhr</td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">voraussichtliche Anzahl der Teilnehmer</td>
                                <td style="padding: 4px;">${appointment.maxAttendees}</td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">Kenntnisbereich (Modul)</td>
                                <td style="padding: 4px;">${appointment.module}</td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">Kenntnisbereich (gem. BKrFQV)</td>
                                <td style="padding: 4px;">
                                    1. Eco -Training & Assistenzsysteme
                                    <div style="padding: 5px; display:flex; justify-content: space-between">
                                        <div>1.1. <input type="checkbox" ${module1 && "checked"}></div>
                                        <div>1.2. <input type="checkbox" ${module4 && "checked"}></div>
                                        <div>1.3. <input type="checkbox" ${module1 && "checked"}></div>
                                        <div>1.3.a <input type="checkbox" ${module1 && "checked"}></div>
                                    </div>
                                    <div style="margin-bottom: 5px; padding: 5px; display:flex; justify-content: space-between">
                                        <div>1.4. <input type="checkbox" ${module2 && "checked"}></div>
                                        <div>1.5. <input type="checkbox" ${module2 && "checked"}></div>
                                        <div>1.6. <input type="checkbox" ${module2 && "checked"}></div>
                                    </div>
                                    2. Sozialvorschriften & Fahrtenschreiber
                                    <div style="margin-bottom: 5px; padding: 5px; display:flex; justify-content: space-between">
                                        <div>2.1. <input type="checkbox" ${module3 && "checked"}></div>
                                        <div>2.2. <input type="checkbox"></div>
                                        <div>2.3. <input type="checkbox" ${module3 && "checked"}></div>
                                    </div>
                                    3. Gefahrenwahrnehmung
                                    <div style="padding: 5px; display:flex; justify-content: space-between">
                                        <div>3.1. <input type="checkbox" ${module4 && "checked"}></div>
                                        <div>3.2. <input type="checkbox" ${module4 && "checked"}></div>
                                        <div>3.3. <input type="checkbox" ${module5 && "checked"}></div>
                                        <div>3.4. <input type="checkbox" ${module5 && "checked"}></div>
                                    </div>
                                    <div style="padding: 5px; display:flex; justify-content: space-between">
                                        <div>3.5. <input type="checkbox" ${module4 && "checked"}></div>
                                        <div>3.6. <input type="checkbox" ${module5 && "checked"}></div>
                                        <div>3.7. <input type="checkbox" ${module5 && "checked"}></div>
                                        <div>3.8. <input type="checkbox" ${module5 && "checked"}></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">verantwortlicher Unterrichtsleiter</td>
                                <td style="padding: 4px;"><strong>${this.formatTeacher(appointment.teacher)}</strong></td>
                            </tr>
                            <tr>
                                <td style="padding: 4px;">weitere Ausbilderinnen und Ausbilder</td>
                                <td style="padding: 4px;">Veit, Markus<br/>Offermann, Dirk<br/>Smeets, Wolfgang</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="display:flex; justify-content:space-between">
                        <div style="margin-left: 10px; width: 300px;">
                            <div style="margin-top: 10px;">Mönchengladbach / ${this.formatDate(new Date())}</div>
                            <hr><div style="text-align: center;">Ort / Datum</div>
                        </div>
                        <div style="margin-right: 10px; width: 200px;"><div style="margin-top: 10px; text-align: center">i.V. Boß</div><hr><div style="text-align: center;">Unterschrift</div></div>
                    </div>
                </div>
                `
                const pdfDoc = new jsPDF("p", "pt", "a4")

                await new Promise((resolve) => {
                    pdfDoc.html(pdf, {
                        x: 10,
                        y: 30,
                        callback: () => {
                            resolve()
                        }
                    })
                })
                const pdfBuffer = Buffer.from(pdfDoc.output("arraybuffer"))
                pdfs.push({
                    pdfString: pdfBuffer.toString("base64"),
                    appointmentId:  appointment.id
                })
            }
          
            this.loading = true
            try {
                const sendBezirksregierungEmail = httpsCallable(functions, "sendBezirksregierungEmail")
                const response = await sendBezirksregierungEmail({
                    pdfs: pdfs
                })
                if (!response.data.response == "ok") {
                    console.log("sendBezirksregierungEmail ~ error--:", response)
                }
            } catch (err) {
                console.warn(err)
                this.$store.commit("showAlert", {
                    title: "Email erstellen",
                    message: "Beim Erstellen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                })
            }
            this.loading = false
        }
    },
    watch: {
        "currentTab"(newTab) {
            this.$store.state.app.filter.status = newTab == 0 ? "active" : "all"
        },
        "$store.state.app.filter.status"(status) {
            this.$store.dispatch("setUserData", {
                value: "filter_status",
                status
            })
        }
    },
    mounted() {
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store.dispatch("attachRelevant")
        this.$store.dispatch("getUserData", {
            value: "filter_status",
            defaultValue: "all"
        }).then(val => {
            this.$store.state.app.filter.status = val
            this.currentTab = this.$store.state.app.filter.status == "active" ? 0 : 1
        })
    },
    components: { AppointmentItem, EditAppointmentDialog }
}
</script>