import Vue from "vue"
import Vuex from "vuex"

import app from "./modules/app"
import auth from "./modules/auth"
import appointment from "./modules/appointment"
import user from "./modules/user"
import company from "./modules/company"
import modules from "./modules/modules"
import driver from "./modules/driver"
import snackbar from "./modules/snackbar"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        attachRelevant({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await dispatch("attachAppointments")
                    if (state.auth.userClaims.permissions.appointments || state.auth.userClaims.permissions.companies || state.auth.userClaims.dispatcherFor.length > 0) {
                        await dispatch("attachCompanies")
                    }
                    await dispatch("attachModules")
                    await dispatch("attachMyAttendees")
                    resolve()
                } catch (err) {
                    reject(err)
                }
            })
        }
    },
    modules: {
        app,
        auth,
        appointment,
        user,
        company,
        modules,
        driver,
        snackbar
    }
})
