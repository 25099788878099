import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView
    },
    {
        path: "/appointment/:appointmentID",
        name: "appointment",
        component: () => import(/* webpackChunkName: "appointment" */ "../views/AppointmentView.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ "../views/RegisterView.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue")
    },
    {
        path: "/companies",
        name: "companies",
        component: () => import(/* webpackChunkName: "companies" */ "../views/companies/CompaniesView.vue")
    },
    {
        path: "/companies/invite",
        name: "company_invite",
        component: () => import(/* webpackChunkName: "company_invite" */ "../views/companies/CompanyInviteView.vue")
    },
    {
        path: "/company/:companyID",
        name: "company",
        component: () => import(/* webpackChunkName: "company" */ "../views/companies/CompanyView.vue")
    },
    {
        path: "/log/attendee/:appointmentID/:attendeeID",
        name: "log_attendee",
        component: () => import(/* webpackChunkName: "log_attendee" */ "../views/log/AttendeeView.vue")
    },
    {
        path: "/manage/permissions",
        name: "manage_permissions",
        component: () => import(/* webpackChunkName: "manage_permissions" */ "../views/manage/PermissionsView.vue")
    },
    {
        path: "/manage/billing",
        name: "manage_billing",
        component: () => import(/* webpackChunkName: "manage_billing" */ "../views/manage/BillingView.vue")
    },
    {
        path: "/autobook",
        name: "autobook",
        component: () => import(/* webpackChunkName: "autobook" */ "../views/AutoBookView.vue")
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/ErrorView.vue")
    },
    {
        path: "/visionapi",
        name: "vision_api",
        component: () => import(/* webpackChunkName: "error" */ "../views/VisionAPIView.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
