import { Appointment } from "@/classes/Appointment"
import { firestore } from "@/firebase"
import { startOfDay, subDays } from "date-fns"
import { onSnapshot, collection, orderBy, query, where, collectionGroup } from "firebase/firestore"
import store from ".."

const appointmentRef = collection(firestore, "appointment")

const state = {
    appointments: [],
    myAttendees: [],
    loading: true,
    firebaseListeners: {
        appointments: null,
        myAttendees: null
    }
}
const getters = {
    
}
const actions = {
    attachAppointments({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.appointments) {
                state.loading = false
                resolve(state.appointments)
                return
            }
            state.loading = true
            let snapshotQuery
            if (store.state.auth.userClaims.permissions.appointments || store.state.auth.userClaims.permissions.companies || store.state.auth.userClaims.dispatcherFor.length > 0) {
                snapshotQuery = query(
                    appointmentRef,
                    where("from", ">=", startOfDay(subDays(new Date(), 90))),
                    orderBy("from", "asc")
                )
            } else {
                snapshotQuery = query(
                    appointmentRef,
                    where("attendeeSubs", "array-contains", store.state.auth.profile.sub),
                    orderBy("from", "asc")
                )
            }

            state.firebaseListeners.appointments = onSnapshot(snapshotQuery, (snapshot) => {
                state.appointments = []
                for (const doc of snapshot.docs) {                    
                    state.appointments.push(new Appointment(doc))
                }
                state.loading = false
                resolve(state.appointments)
            }, (error) => {
                state.loading = false
                reject(error)
            })
        })
    },
    detatchAppointments({ state }) {
        if (state.firebaseListeners.appointments) {
            state.firebaseListeners.appointments()
            state.firebaseListeners.appointments = null
            state.loading = true
        }
    },
    attachMyAttendees({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.myAttendees) {
                resolve(state.myAttendees)
                return
            }
            const attendeeRef = query(collectionGroup(firestore, "attendee"), where("sub", "==", store.state.auth.profile.sub))
            state.firebaseListeners.myAttendees = onSnapshot(attendeeRef, (snapshot) => {
                for (const doc of snapshot.docs) {
                    const data = doc.data()
                    data.appointmentID = doc.ref.parent.parent.id                    
                    state.myAttendees.push(data)
                }
                resolve(state.myAttendees)
            }, (err) => {
                reject(err)
            })
        })
    }
}
export default { state, getters, actions }