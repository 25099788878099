import { firestore } from "@/firebase"
import { collection, doc, setDoc, updateDoc } from "firebase/firestore"
import { Address } from "./Address"
import { Birthday } from "./Attendee"

export class User {
    #id = null

    firstName = null
    lastName = null
    birthday = null
    address = null
    email = null
    emails = []
    permissions = null
    picture = null
    companies = []

    constructor(doc = null) {
        if (doc) {
            this.#id = doc.id

            const data = doc.data()
            this.firstName = data.firstName
            this.lastName = data.lastName
            this.email = data.email
            if (data.birthday) {
                this.birthday = new Birthday(data.birthday)
            }
            if (data.address) {
                this.address = new Address(data.address)
            }
            if (data.permissions) {
                this.permissions = data.permissions
            }
            if (data.picture) {
                this.picture = data.picture
            }
            this.emails = data.emails
            this.companies = data.companies
        }
    }
    async createUser(sub) {
        this.#id = sub
        await this.toFirestore()
    }
    async toFirestore() {
        const userRef = doc(collection(firestore, "user"), this.#id)
        await setDoc(userRef, this.#toData(), { merge : true })
    }
    async setPermissions(permissions) {
        this.permissions = permissions
        const userRef = doc(collection(firestore, "user"), this.#id)
        await updateDoc(userRef, "permissions", this.permissions)
    }
    #toData() {
        const data = {}
        if (this.firstName) {
            data.firstName = this.firstName
        }
        if (this.lastName) {
            data.lastName = this.lastName
        }
        if (this.birthday) {
            data.birthday = this.birthday
        }
        if (this.email) {
            data.email = this.email
        }
        if (this.emails) {
            data.emails = this.emails
        }
        if (this.permissions) {
            data.permissions = this.permissions
        }
        if (this.picture) {
            data.picture = this.picture
        }
        if (this.address) {
            data.address = this.address
        }
        return data
    }

    get id() {
        return this.#id
    }
}