import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import de from "vuetify/lib/locale/de"

Vue.use(Vuetify)

let dark = false
if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    dark = true
}

export default new Vuetify({
    theme: {
        dark: dark,
        themes: {
            light: {
                primary: "#9c0047",
                secondary: "#2b6ea9",
                accent: "#275c9b",
                error: "#b71c1c",
                navigation: "#fff",
                primaryLight: "#9c0047"
            },
            dark: {
                primary: "#9c0047",
                secondary: "#2b6ea9",
                accent: "#275c9b",
                error: "#f34848",
                primaryLight: "#fff"
            }
        }
    },
    lang: {
        locales: { de },
        current: "de"
    }
})