import { firestore } from "@/firebase"
import { onSnapshot, doc, collection, setDoc } from "firebase/firestore"
import store from ".."

const state = {
    alert: {
        title: "",
        message: "",
        loading: false,
        ok: true,
        show: false
    },
    filter: {
        module: "all",
        location: "all",
        month: "all",
        status: "active"
    },
    settings: {},
    firebaseListeners: {
        settings: null
    },
    dialogs: {
        settings: false
    }
}
const getters = {
    viewType() {
        if (store.state.auth.userClaims.permissions.appointments) {
            return "appointment_editor"
        }
        if (store.state.auth.userClaims.permissions.companies) {
            return "company_editor"
        }
        if (store.state.auth.userClaims.dispatcherFor.length > 0) {
            return "dispatcher"
        }
        return "driver"
    }
}
const mutations = {
    showAlert(state, payload) {
        state.alert.title = payload.title
        state.alert.message = payload.message
        state.alert.ok = "ok" in payload ? payload.ok : true
        state.alert.loading = "loading" in payload ? payload.loading : false
        state.alert.show = true
    }
}
const actions = {
    setSetting({ state }, { key, val }) {
        return new Promise((resolve, reject) => {
            const ref = doc(collection(firestore, "settings"), "default")
            const docToUpdate = {}
            docToUpdate[key] = val
            setDoc(ref, docToUpdate, { merge: true }).then(() => {
                state.settings[key] = val
                resolve(state.settings[key])
            }).catch(err => {
                reject(err)
            })
        })
    },
    getSetting({ state }, { key, def }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.settings) {
                if (key in state.settings) {
                    resolve(state.settings[key])
                    return
                } else {
                    resolve(def)
                    return
                }
            }
            const ref = doc(collection(firestore, "settings"), "default")
            state.firebaseListeners.settings = onSnapshot(ref, (settingsDoc) => {
                if (settingsDoc.exists()) {
                    state.settings = settingsDoc.data()
                } else {
                    state.settings = {}
                }
                if (key in state.settings) {
                    resolve(state.settings[key])
                    return
                } else {
                    resolve(def)
                    return
                }
            }, (err) => {
                state.settings = {}
                reject(err)
            })
        })
    }
}
export default { state, getters, mutations, actions }