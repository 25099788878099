export class Address {
    street = null
    location = null
    number = null
    postcode = null
    building = null

    constructor(data = null) {
        if (data) {
            this.street = data.street
            this.location = data.location
            this.number = data.number
            this.postcode = data.postcode
            this.building = data.building || ""
        }
    }

    toData() {
        return {
            street: this.street,
            location: this.location,
            number: this.number,
            postcode: this.postcode,
            building: this.building
        }
    }
}