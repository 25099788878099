import { Driver } from "@/classes/Driver"

const state = {
    drivers: new Map()
}
const actions = {
    getDriver({ state }, { company, driverID, companyID = "" }) {
        return new Promise(async (resolve, reject) => {
            try {
                if (companyID != "") {
                    if (state.drivers.has(`${companyID}/${driverID}`)) {
                        resolve(state.drivers.get(`${companyID}/${driverID}`))
                        return
                    }
                }
                if (state.drivers.has(`${company.id}/${driverID}`)) {
                    resolve(state.drivers.get(`${company.id}/${driverID}`))
                    return
                }
                const driver = new Driver(company)
                if (await driver.fromFirestore(driverID)) {
                    state.drivers.set(`${company.id}/${driverID}`, driver)
                    resolve(driver)
                } else {
                    reject("driver_not_found")
                }
            } catch (err) {
                console.warn(err)
                reject("driver_not_found")
            }
        })
    }
}
export default { state, actions }
