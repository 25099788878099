const state={
    show: false,
    message: "",
    color:""
}

const mutations = {
    showSnackbar(state, { message = "Default message", color = "success" }){
        state.message = message
        state.color=color
        state.show = true
    },
    closeSnackbar(state) {
        state.message = ""
        state.color = ""
        state.show = false
    }
}

export default {state, mutations}
