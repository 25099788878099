import { waitForFirebaseAuth, auth, functions } from "./firebase"
import { getIdTokenResult, signInWithCustomToken } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import store from "./store"
import router from "./router"

const configureVisibleDropdownButtons = () => {
    const hasAnyPermission = Object.values(store.state.auth.userClaims.permissions).some(value => value === true)
    if (hasAnyPermission) {
        loginNew.dropdown.layout().item("btn-permissions").show()
    }
    if (store.state.auth.userClaims.permissions.appointments) {
        loginNew.dropdown.layout().item("btn-appointments").show()
        loginNew.dropdown.layout().item("btn-kba").show()
    }
    if (store.state.auth.userClaims.permissions.companies) {
        loginNew.dropdown.layout().item("btn-companies").show()
    }
    if (store.state.auth.userClaims.permissions.pricing) {
        loginNew.dropdown.layout().item("btn-pricing").show()
    }
    if (store.state.auth.userClaims.permissions.billing) {
        loginNew.dropdown.layout().item("btn-billing").show()
    }
    if (store.state.auth.userClaims.autobook) {
        loginNew.dropdown.layout().item("btn-autobook").show()
    }
}

loginNew.init(
    process.env.VUE_APP_LOGIN_NEW_CLIENT_ID,
    location.protocol + "//" + location.host + "/login",
    [],
    "openid profile email aid"
)

loginNew.dropdown.layout().register([
    {
        id: "btn-appointments",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Terminerstellung",
        icon: "mdi-home",
        hidden: true,
        callback: () => {
            router.push("/").catch(() => {})
        }        
    },
    {
        id: "btn-permissions",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Berechtigungen",
        icon: "mdi-security",
        hidden: true,
        callback: () => {
            router.push("/manage/permissions").catch(() => {})
        }
    },
    {
        id: "btn-pricing",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Preisgestaltung & Einstellungen",
        icon: "mdi-cash",
        hidden: true,
        callback: () => {
            store.state.app.dialogs.settings = true
        }
    },
    {
        id: "btn-companies",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Unternehmen",
        icon: "mdi-domain",
        hidden: true,
        callback: () => {
            router.push("/companies").catch(() => {})
        }
    },
    {
        id: "btn-billing",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Rechnungserstellung",
        icon: "mdi-cash",
        hidden: true,
        callback: () => {
            router.push("/manage/billing").catch(() => {})
        }
    },
    {
        id: "btn-autobook",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Automatische Buchung",
        icon: "mdi-account-convert",
        hidden: true,
        callback: () => {
            router.push("/autobook").catch(() => {})
        }
    },
    {
        id: "btn-kba",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "KBA-Online",
        icon: "mdi-bus",
        hidden: true,
        callback: () => {
            window.open("https://kba-online.de", "_blank").focus()
        }
    }
]).show()

loginNew.onAuthStateChanged(async () => {
    store.state.auth.isAuthenticating = true
    if(loginNew.isSignedIn()) {
        await waitForFirebaseAuth()
        const profile = loginNew.getProfile()
        if(auth.currentUser && auth.currentUser.uid  == profile.sub) {
            const tokenResult = await getIdTokenResult(auth.currentUser, true)
            store.commit("setUserClaims", tokenResult.claims)
            store.commit("setUserProfile", profile)
            await store.dispatch("attachUserData")
            store.state.auth.isSignedIn = true
            store.state.auth.isAuthenticating = false
            store.state.auth.authDone = true
            configureVisibleDropdownButtons()
        }else{
            const getAuthToken = httpsCallable(functions, "getAuthToken")
            const response = await getAuthToken({ token: loginNew.getIdToken() })
            if (response.data.response === "ok") {
                await signInWithCustomToken(auth, response.data.token)
                const tokenResult = await getIdTokenResult(auth.currentUser, true)
                store.commit("setUserClaims", tokenResult.claims)
                store.commit("setUserProfile", profile)
                await store.dispatch("attachUserData")
                store.state.auth.isSignedIn = true
                store.state.auth.isAuthenticating = false
                store.state.auth.authDone = true
                configureVisibleDropdownButtons()
            }else{
                if(response.data.error == "invalid_permissions") {
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/permissions").catch(() => {})
                    configureVisibleDropdownButtons()
                }else{
                    console.error(response.data)
                    store.commit("setUserClaims", null)
                    store.commit("setUserProfile", profile)
                    store.state.auth.isSignedIn = false
                    store.state.auth.isAuthenticating = false
                    store.state.auth.authDone = true
                    router.push("/error").catch(() => {})
                    configureVisibleDropdownButtons()
                }
            }
        }
    }else{
        store.commit("setUserClaims", null)
        store.commit("setUserProfile", {})
        await waitForFirebaseAuth()
        if(auth.currentUser != null) {
            await auth.signOut()
        }
        store.state.auth.isSignedIn = false
        store.state.auth.isAuthenticating = false
        store.state.auth.authDone = true
        configureVisibleDropdownButtons()
    }
})
