import { firestore } from "@/firebase"
import { collection, onSnapshot, getDoc, doc, query, where, setDoc } from "firebase/firestore"
import { User } from "@/classes/User"
import store from ".."

const userRef = collection(firestore, "user")

const state = {
    allUsers: [],
    allUsersLoading: true,
    publicUserInfos: {},
    companyUsers: [],
    companyUsersLoading: true,
    userData: {},
    userDataLoading: true,
    teachers: [],
    teachersLoading: true,
    firebaseListeners: {
        companyUsers: null,
        allUsers: null,
        userData: null,
        teachers: null
    }
}
const getters = {
    
}
const actions = {
    attachAllUserProfiles({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.allUsers) {
                state.allUsersLoading = false
                resolve(state.allUsers)
                return
            }
            state.allUsersLoading = true
            state.firebaseListeners.allUsers = onSnapshot(userRef, (snapshot) => {
                state.allUsers = []
                for (const userDoc of snapshot.docs) {
                    state.allUsers.push(new User(userDoc))
                }
                state.allUsersLoading = false
                resolve(state.allUsers)
            }, (err) => {
                state.allUsersLoading = false
                reject(err)
            })
        })
    },
    attachCompanyProfiles({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.companyUsers) {
                state.companyUsersLoading = false
                resolve(state.companyUsers)
                return
            }
            if (store.state.auth.userClaims.permissions.appointments || store.state.auth.userClaims.permissions.companies) {
                state.firebaseListeners.companyUsers = onSnapshot(userRef, (snapshot) => {
                    state.companyUsersLoading = false
                    state.companyUsers = []
                    for (const userDoc of snapshot.docs) {
                        state.companyUsers.push(new User(userDoc))
                    }
                    resolve(state.companyUsers)
                }, (error) => {
                    state.companyUsersLoading = false
                    reject(error)
                })
            } else {
                const dispatcherArray = []
                for (const dispatcherInfo of store.state.auth.userClaims.dispatcherFor) {
                    dispatcherArray.push(dispatcherInfo.companyID)
                }
                if (dispatcherArray.length > 0) {
                    state.companyUsersLoading = true
                    const queryRef = query(userRef, where("companies", "array-contains-any", dispatcherArray))
                    state.firebaseListeners.companyUsers = onSnapshot(queryRef, (snapshot) => {
                        state.companyUsersLoading = false
                        state.companyUsers = []
                        for (const userDoc of snapshot.docs) {
                            state.companyUsers.push(new User(userDoc))
                        }
                        resolve(state.companyUsers)
                    }, (error) => {
                        state.companyUsersLoading = false
                        reject(error)
                    })
                }
            }
        })
    },
    getPublicUserInfo({ state }, sub) {
        return new Promise((resolve, reject) => {
            if (state.publicUserInfos[sub]) {
                resolve(state.publicUserInfos[sub])
                return
            }
            getDoc(doc(collection(firestore, "public_user"), sub)).then(userDoc => {
                if (userDoc.exists) {
                    state.publicUserInfos[sub] = userDoc.data()
                    resolve(state.publicUserInfos[sub])
                } else {
                    reject(new Error("user_not_found"))
                }
            }).catch(err => {
                console.error(err)
                reject(new Error("unknown_error"))
            })
        })
    },
    attachUserData({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.userData) {
                state.userDataLoading = false
                resolve(state.userData)
                return
            }
            state.userDataLoading = true
            const userDataRef = doc(collection(firestore, "user_data"), store.state.auth.profile.sub)
            state.firebaseListeners.userData = onSnapshot(userDataRef, (snapshot) => {
                if (snapshot.exists) {
                    state.userData = snapshot.data()
                } else {
                    state.userData = {}
                }
                state.userDataLoading = false
                resolve(state.userData)
            }, (err) => {
                state.userDataLoading = false
                reject(err)
            })
        })
    },
    attachTeachers({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.teachers) {
                state.teachersLoading = false
                resolve(state.teachers)
                return
            }
            state.teachersLoading = true
            const ref = query(collection(firestore, "public_user"), where("teacher", "==", true))
            state.firebaseListeners.teachers = onSnapshot(ref, (snapshot) => {
                state.teachers = []
                for (const doc of snapshot.docs) {
                    const data = doc.data()
                    data.sub = doc.id
                    state.teachers.push(data)
                }
                resolve(state.teachers)
            }, (err) => {
                reject(err)
            })
        })
    },
    getUserData({ state }, { value, defaultValue }) {
        if (state.userData && value in state.userData) {
            return state.userData[value]
        }
        return defaultValue
    },
    setUserData(_, { value, status }) {
        return new Promise((resolve) => {
            const userDataRef = doc(collection(firestore, "user_data"), store.state.auth.profile.sub)
            const data = {}
            data[value] = status
            setDoc(userDataRef, data, { merge: true })
            resolve()
        })
    }
}
export default { state, getters, actions }