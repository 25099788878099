import { firestore } from "@/firebase"
import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore"

export class Dispatcher {
    sub = null
    email = null

    constructor(dispatcherData = null) {
        if (dispatcherData) {
            this.sub = dispatcherData.sub
            this.email = dispatcherData.email
        }
    }
}
export class Company {
    #id = null

    name = null
    slots = 0
    dispatcher = {}
    invites = []
    internal = false

    constructor(doc = null) {
        if (doc) {
            this.#id = doc.id

            const data = doc.data()
            this.name = data.name
            this.slots = data.slots || 0
            this.dispatcher = data.dispatcher
            this.invites = data.invites
            this.internal = data.internal == true
        }
    }

    async toFirestore() {
        if (this.#id) {
            await setDoc(this.firestoreRef, this.#toData(), { merge: true })
        } else {
            const doc = await addDoc(collection(firestore, "company"), this.#toData(), { merge: true })
            this.#id = doc.id
        }
    }
    async invite(email) {
        for (const invite of this.invites) {
            if (invite.email == email) {
                return
            }
        }
        this.invites.push({
            email: email,
            send: false
        })
        await this.toFirestore()
    }
    async removeInvite(email) {
        let index = 0
        for (const invite of this.invites) {
            if (invite.email == email) {
                this.invites.splice(index, 1)
                await this.toFirestore()
                return
            }
            index++
        }
    }
    async removeDispatcher(sub) {
        delete this.dispatcher[sub]
        await this.toFirestore()
    }
    async remove() {
        const collectionRef = collection(firestore, "company")
        await deleteDoc(doc(collectionRef, this.#id))
    }

    #toData() {
        return {
            name: this.name,
            slots: this.slots,
            dispatcher: this.dispatcher,
            invites: this.invites,
            internal: this.internal
        }
    }
    get firestoreRef() {
        const collectionRef = collection(firestore, "company")
        if (this.#id) {
            return doc(collectionRef, this.#id)
        }
        return doc(collectionRef)
    }
    get id() {
        return this.#id
    }
}