<template>
<v-dialog ref="dialog" v-model="timePickDialog" :return-value.sync="time" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
			<v-text-field v-model="time" :label="label" :prepend-icon="icon" readonly v-bind="attrs" v-on="on" hide-details color="primaryLight"></v-text-field>
		</template>
        <v-time-picker v-if="timePickDialog" v-model="time" full-width format="24hr" color="primary">
			<v-spacer></v-spacer>
			<v-btn text @click="timePickDialog = false" >Abbrechen</v-btn>
			<v-btn text color="primaryLight" @click="$refs.dialog.save(time)" >OK</v-btn>
        </v-time-picker>
      </v-dialog>
</template>
<script>
import { format } from "date-fns"

export default {
    data: () => ({
        time: format(new Date(), "HH:mm"),
        timePickDialog: false
    }),
    props: {
        label: {
            type: String,
            default: "Uhrzeit"
        },
        icon: {
            type: String,
            default: "mdi-clock-time-four-outline"
        },
        value: {
            type: String,
            default: format(new Date(), "HH:mm")
        }
    },
    mounted() {
        this.time = this.value
    },
    watch: {
        value(val) {
            this.time = val
        },
        time(val) {
            this.$emit("input", val)
            this.$emit("value", val)
        }
    }
}
</script>