<template>
	<v-app>
        <SnackBar></SnackBar>
        <AppNavigation></AppNavigation>
        <AlertDialog></AlertDialog>
        <SettingsDialog v-if="$store.state.auth.userClaims.permissions.pricing"></SettingsDialog>
		<v-main>
            <v-divider></v-divider>
            <v-container fluid>
                <div v-if="isLoading" class="text-center">
                    <v-progress-circular indeterminate color="primaryLight"></v-progress-circular>
                </div>
                <div v-else>
                    <router-view />
                </div>
            </v-container>
		</v-main>
        <v-footer padless>
            <v-row justify="center" no-gutters>
                <v-btn href="https://www.new.de/impressum" target="_blank" text small class="my-2">Impressum</v-btn>
                <v-btn href="https://datenschutz.new.de/busfahrschule" target="_blank" text small class="my-2">Datenschutz</v-btn>
                <v-btn @click="openPrivacySettings" text small class="my-2">Datenschutzeinstellungen</v-btn>
                <v-col class="py-4 text-center" cols="12">
                    © NEW AG {{ new Date().getFullYear() }}
                </v-col>
            </v-row>
        </v-footer>
	</v-app>
</template>

<script>
import AppNavigation from "@/components/app/AppNavigation.vue"
import AlertDialog from "@/components/app/AlertDialog.vue"
import SettingsDialog from "./components/dialogs/SettingsDialog.vue"
import SnackBar from "./components/app/SnackBar.vue"

export default {
    name: "App",
    computed: {
        isLoading() {
            return this.$store.state.auth.isAuthenticating || !this.$store.state.auth.authDone
        }
    },
    methods: {
        openPrivacySettings() {
            loginNew.showCookieDialog()
        }
    },
    watch: {
        "$store.state.company.selectedCompanyID": {
            handler: function () {
                this.$store.dispatch("attachDrivers")
            },
            immediate: true
        },
        "$store.state.auth.userClaims.dispatcherFor": {
            handler: function (dispatcherFor) {
                if (dispatcherFor.length > 0) {
                    loginNew.dropdown.layout().item("btn-companies").show()
                }
            },
            immediate: true
        }
    },
    components: {
        AppNavigation,
        AlertDialog,
        SettingsDialog,
        SnackBar
    }
}
</script>
<style>
.app-container {
	width: 100%;
	max-width: 1000px !important;
	margin: 0 auto;
}
.cursor-pointer {
    cursor: pointer;
}
#loginnew-dropdown .login-new-avatar span {
	display: none;
}
header.v-app-bar {
	contain: unset;
	transform: none !important;
	overflow: visible;
}
.text-cr {
    text-align: center;
}
.text-cl {
    text-align: center;
}
@media (min-width: 600px) {
    .text-cr {
        text-align: right;
    }
    .text-cl {
        text-align: left;
    }
}
</style>
