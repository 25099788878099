import { firestore } from "@/firebase"
import { collection, onSnapshot } from "firebase/firestore"

const state = {
    modules: [],
    loading: true,
    firestoreListener: null
}
const actions = {
    attachModules({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener != null) {
                resolve(state.modules)
                state.loading = false
                return
            }
            state.loading = true
            state.firestoreListener = onSnapshot(collection(firestore, "module"), (snapshot) => {
                state.modules = []
                for (const doc of snapshot.docs) {
                    state.modules.push({ number: doc.data().number, name: doc.data().name })
                }
                state.loading = false
                resolve(state.modules)
            }, (err) => {
                reject(err)
            })
        })
    }
}
export default { state, actions }