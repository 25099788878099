<template>
<v-dialog ref="dialog" v-model="datePickDialog" :return-value.sync="date" persistent width="290px">
    <template v-slot:activator="{ }">
        <v-text-field
            v-if="checkable"
            v-model="formattedDate"
            :disabled="disabled"
            :label="label"
            :prepend-icon="prependIcon"
            @click:prepend="!readonly ? checked = !checked : null"
            :readonly="readonly||!checked"
            color="primaryLight"
            :hint="error&&checked? 'Error: Das Datumsformat sollte dd.MM.yyyy': null"
            :error="error&&checked"
            :persistent-hint="error&&checked"
            :hide-details="!error"
        >
            <template v-slot:append>
              <v-icon @click="!readonly ? datePickDialog = true : null">{{ icon }}</v-icon>
            </template>
        </v-text-field>
        <v-text-field
            v-else
            v-model="formattedDate"
            :disabled="disabled"
            :label="label"
            placeholder="dd.MM.yyyy"
            :readonly="readonly"
            color="primaryLight"
            :hint="error? 'Error: Das Datumsformat sollte dd.MM.yyyy': null"
            :error="error"
            :persistent-hint="error"
            :hide-details="!error"
            >
            <template v-slot:append>
              <v-icon @click="!readonly ? datePickDialog = true : null">{{ prependIcon }}</v-icon>
            </template>
        </v-text-field>
    </template>
    <v-date-picker v-model="calendarDate" scrollable color="primary" :first-day-of-week="1">
        <v-spacer></v-spacer>
        <v-btn text @click="datePickDialog = false">Abbrechen</v-btn>
        <v-btn text color="primaryLight" @click="checked=true;$refs.dialog.save(date)">OK</v-btn>
    </v-date-picker>
</v-dialog>
</template>
<script>
import { format } from "date-fns"

export default {
    data: () => ({
        date: format(new Date(), "yyyy-MM-dd"),
        checked: false,
        datePickDialog: false,
        calendarDate: format(new Date(), "yyyy-MM-dd"),
        formattedDate: format(new Date(), "dd.MM.yyyy"),
        error: false
    }),
    props: {
        label: {
            type: String,
            default: "Datum"
        },
        icon: {
            type: String,
            default: "mdi-calendar"
        },
        value: {
            type: String,
            default: format(new Date(), "yyyy-MM-dd")
        },
        checkable: {
            type: Boolean,
            default: false
        },
        disabledText: {
            type: String,
            default: "Deaktiviert"
        },
        defaultDate: {
            type: String,
            default: format(new Date(), "yyyy-MM-dd")
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        prependIcon() {
            if (this.checkable) {
                if (this.checked) {
                    return "mdi-checkbox-marked"
                } else {
                    return "mdi-checkbox-blank-outline"
                }
            } else if (this.icon) {
                return this.icon
            }
            return null
        }
        // formattedDate() {
        //     if (this.checkable && !this.checked) {
        //         return ""
        //     }
        //     return format(new Date(this.calendarDate), "dd.MM.yyyy")
        // }
    },
    mounted() {
        this.checked = this.value != null
        this.calendarDate = this.value
    },
    watch: {
        value(val) {        
            this.calendarDate = val
        },
        checked(checked) {
            if (checked) {
                if (!this.calendarDate) {
                    this.calendarDate = this.defaultDate
                }
            } else {
                this.calendarDate = null
            }
        },
        // date(val) {
        //     this.checked = val != null
        //     if (this.checkable && !this.checked) {
        //         this.$emit("input", null)
        //         this.$emit("value", null)
        //         return
        //     }
            
        //     this.$emit("input", val)
        //     this.$emit("value", val)
        // },
        formattedDate(newFormattedDate) {
            const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/

            if(dateRegex.test(newFormattedDate)){
                this.error=false
                this.sendDate(this.convertDateFormat(newFormattedDate))
            }else{this.error=true}
        },
        calendarDate(newCalendarDate){
            if (this.checkable && !this.checked) {
                this.formattedDate = ""
            }
            this.formattedDate = newCalendarDate && format(new Date(newCalendarDate), "dd.MM.yyyy")
            this.sendDate(newCalendarDate)
        }
    },
    methods: {
        sendDate(val){
            this.checked = val != null
            if (this.checkable && !this.checked) {
                this.$emit("input", null)
                this.$emit("value", null)
                return
            }
            this.$emit("input", val)
            this.$emit("value", val)
        },
        convertDateFormat(dateString) {
            const [day, month, year] = dateString.split(".")
            return `${year}-${month}-${day}`
        }
    }
  
}
</script>
