<template>
	<v-app-bar app elevate-on-scroll color="navigation">
        <v-col cols="2" class="pa-0 d-flex">
            <v-btn @click="onGoHome" icon v-if="canGoBack" class="mr-2">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <img class="v-app-bar-new-icon mr-5" :src="$vuetify.theme.dark ? images.logoDark : images.logo" width="90px">
        </v-col>
        <v-col class="justify-space-around d-none d-sm-flex">
            <v-toolbar-title>Berufskraftfahrerqualifikation</v-toolbar-title>
        </v-col>
        <v-spacer class="justify-space-around d-flex d-sm-none"></v-spacer>
        <v-col cols="2" class="d-flex justify-end pa-0">
            <div v-show="!hideProfilePanel">
                <div id="loginnew-dropdown"></div>
            </div>
        </v-col>
        <template v-if="$store.state.company.companies.length > 1" v-slot:extension>
            <div class="app-container">
                <v-select
                v-model="$store.state.company.selectedCompanyID"
                :loading="$store.state.company.loading || $store.state.company.loadingDrivers"
                :items="companies"
                label="Unternehmen"
                hide-details
                solo-inverted
                flat
                dense
                prepend-inner-icon="mdi-bus"/>
            </div>
            
        </template>
	</v-app-bar>
</template>
<script>
export default {
    data() {
        return {
            images: {
                logo: require("@/assets/logo_colored.svg"),
                logoDark: require("@/assets/logo_dark.svg")
            }
        }
    },
    computed: {
        companies() {
            const companies = []
            for (const company of this.$store.state.company.companies) {
                companies.push({
                    value: company.id,
                    text: company.name
                })
            }
            return companies
        },
        hideProfilePanel() {
            switch(this.$route.name) {
                case "register":
                case "company_invite":
                    return true
            }
            return false
        },
        canGoBack() {
            switch(this.$route.name) {
                case "appointment":
                case "manage_permissions":
                case "companies":
                case "company":
                case "manage_signature":
                case "log_attendee":
                case "autobook":
                case "manage_billing":
                    return true
                default:
                    return false
            }
        }
    },
    methods: {
        onGoHome() {
            switch(this.$route.name) {
                case "appointment":
                case "manage_permissions":
                case "companies":
                case "autobook":
                case "manage_billing":
                    this.$router.push("/")
                    return
                case "company":
                    this.$router.push("/companies")
                    return
                case "log_attendee":
                    this.$router.push(`/appointment/${this.$route.params.appointmentID}`)
                    return
                default:
                    return false
            }
        }
    },
    watch: {
        "$store.state.company.selectedCompanyID"(companyID) {
            this.$store.dispatch("setUserData", {
                value: "selected_company",
                status: companyID
            })
        }
    }
}
</script>
<style>
.v-toolbar__extension {
    z-index: -1 !important;
}
</style>
<style scoped>
.select-company-input {
    width: 100%;
    max-width: 450px;
    z-index: -1;
}
</style>